import {
  addCustomerStudents,
  create,
  destroy,
  edit,
  getAll,
  getById,
  getCustomerStudents,
  notifyCustomerStudents,
  removeCustomerStudent,
  toggleCustomerDisabled,
} from "@/services/customerService";
import { removeDataFromState } from "@/shared/utils";
import * as _ from "lodash";
const state = {
  customers: [],
  customer: {},
  customerStudents: [],
  customersPage: 1,
  customersTotal: 0,
  customersPagesNumber: 0,
  customersLoading: false,
};

const getters = {
  getCustomers: (state) => state.customers,
  getCustomer: (state) => state.customer,
  getCustomersPage: (state) => state.customersPage,
  getCustomersTotal: (state) => state.customersTotal,
  getCustomersPagesNumber: (state) => state.customersPagesNumber,
  getCustomersLoading: (state) => state.customersLoading,
  getCustomerStudents: (state) => state.customerStudents,
};

const mutations = {
  setCustomers(state, payload) {
    state.customers = _.get(payload, "data");
    state.customersPage = _.get(payload, "current_page");
    state.customersTotal = _.get(payload, "total");
    state.customersPagesNumber = _.get(payload, "last_page");
  },
  setCustomersLoading(state, payload) {
    state.customersLoading = payload;
  },
  insertCustomer(state, payload) {
    const exist = _.find(state.customers, { id: payload.id });

    if (!exist) {
      state.customers.push(payload);
    }
  },
  setCustomerStudents(state, payload) {
    state.customerStudents = payload;
  },
  removeCustomerStudent(state, { payload }) {
    state.customerStudents = removeDataFromState(
      state.customerStudents,
      payload.id
    );

    state.customerStudentsTotal = _.size(state.customerStudents);
  },
  setCustomer(state, payload) {
    state.customer = payload;
  },
};

const actions = {
  setCustomersLoading({ commit }, payload) {
    commit("setCustomersLoading", payload);
  },
  async setCustomers({ commit }, payload) {
    try {
      commit("setCustomersLoading", true);

      const { data } = await getAll({
        page: _.get(payload, "page", 1),
        search: _.get(payload, "search", ""),
        perPage: _.get(payload, "perPage", 25),
        formList: _.get(payload, "formList", ""),
      });

      commit("setCustomers", data);
    } catch (error) {
      console.error(error);
    }

    commit("setCustomersLoading", false);
  },

  async createCustomer({ commit }, payload) {
    await create(payload);
  },

  async editCustomer({ commit }, { id, payload }) {
    await edit(id, payload);
  },

  async deleteCustomer({ commit, state, dispatch }, id) {
    try {
      commit("setCustomersLoading", true);
      await destroy(id);

      state.customers = removeDataFromState(state.customers, id);
      state.customersTotal = _.size(state.customers);

      dispatch("showSnackbar", "Customer has been removed.");
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }

    commit("setCustomersLoading", false);
  },

  async getCustomerById({ commit }, id) {
    const { data } = await getById(id);
    commit("insertCustomer", data);
  },

  async setCustomer({ commit }, id) {
    try {
      commit("setCustomersLoading", true);
      const { data } = await getById(id);

      commit("setCustomer", data);
    } catch (error) {
      console.error(error);
    }

    commit("setCustomersLoading", false);
  },

  async setCustomerStudents({ commit }, payload) {
    const params = {
      id: _.get(payload, "id"),
    };

    try {
      commit("setCustomersLoading", true);
      const { data } = await getCustomerStudents(params);

      commit("setCustomerStudents", data);
    } catch (error) {
      console.error(error);
    }

    commit("setCustomersLoading", false);
  },

  async addCustomerStudents({ commit, dispatch }, { id, payload }) {
    try {
      commit("setCustomersLoading", true);
      const { data } = await addCustomerStudents(id, payload);

      await dispatch("setAvailableStudents");

      commit("setCustomerStudents", data);
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }

    commit("setCustomersLoading", false);
  },

  async removeCustomerStudent({ commit, state, dispatch }, id) {
    try {
      commit("setCustomersLoading", true);
      await removeCustomerStudent(id);

      state.customerStudents = removeDataFromState(state.customerStudents, id);
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }

    commit("setCustomersLoading", false);
  },

  async notifyCustomerStudents({ commit, dispatch }, { id, payload }) {
    try {
      commit("setCustomersLoading", true);
      const { data } = await notifyCustomerStudents(id, payload);

      commit("setCustomersLoading", false);

      return data;
    } catch (error) {
      console.error("DEBUG: error", error);
      commit("setCustomersLoading", false);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }
  },

  async toggleCustomerDisabled({ dispatch, state }, { id, payload }) {
    try {
      await toggleCustomerDisabled(id, payload);

      state.customers = removeDataFromState(state.customers, id);
      state.customersTotal = _.size(state.customers);
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
