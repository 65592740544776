/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import octaviaKit from "@/plugins/octavia.kit";
import { rehydrateToken } from "@/services/authService";
import axios from "axios";
import _ from "lodash";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import DatetimePicker from "vuetify-datetime-picker";
import App from "./App.vue";
import "./plugins";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

Vue.component("apexchart", VueApexCharts);
Vue.use(octaviaKit);
Vue.use(DatetimePicker);
Vue.use(VuePlyr, {
  plyr: {
    controls: [
      "play-large",
      "play",
      "progress",
      "current-time",
      "mute",
      "fullscreen",
    ],
  },
});
Vue.config.productionTip = false;

axios.interceptors.request.use((config) => {
  const token = store.getters.getToken;

  if (token) {
    config.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (response.status >= 200 && response.status < 300) {
      const excluded = [
        "clients/check",
        "users/rehydrate",
        "users/login",
        "users/forgot-password",
        "users/reset-password",
      ];

      const url = _.get(response, "config.url");

      if (!excluded.some((path) => url.includes(path))) {
        try {
          const { data } = await rehydrateToken();
          store.commit("setUser", data);
        } catch (error) {
          return Promise.reject(response);
        }
      }

      return Promise.resolve(response);
    }

    return Promise.reject(response);
  },
  (error) => {
    if (error.response.status) {
      if (error.response.status === 401 || error.response.status === 403) {
        store.dispatch("signOut");
      }

      return Promise.reject(error.response);
    }

    return Promise.resolve(response);
  }
);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
