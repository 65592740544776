import store from "@/store";

export default async (to, from, next) => {
  await store.dispatch("setCourseItems", {
    id: _.get(to, "params.id")
  });

  await store.dispatch("setCourseParentItems", {
    id: _.get(to, "params.id")
  });

  await store.dispatch("setCourseLookup");

  next();
};
