import { objToUrlParam } from "@/shared/utils";
import axios from "axios";

export const getAllCourse = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/course/get-all?${objToUrlParam(payload)}`
  );
};

export const saveCourse = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/course/save-course`,
    payload
  );
};

export const editCourse = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/course/edit-course/${payload.get("id")}`,
    payload
  );
};

export const deleteCourse = (id) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/course/delete-course/${id}`
  );
};

export const getCourseItems = (payload) => {
  return axios.get(
    `${
      process.env.VUE_APP_API_URL
    }/course-items/get-by-course-id?${objToUrlParam(payload)}`
  );
};

export const getCourseParentItems = (payload) => {
  return axios.get(
    `${
      process.env.VUE_APP_API_URL
    }/course-items/get-parent-items?${objToUrlParam(payload)}`
  );
};

export const getCourseItemQuestions = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/course-items/get-questions?${objToUrlParam(
      payload
    )}`
  );
};

export const createCourseItem = (payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/course-items`, payload);
};

export const updateCourseItem = (id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/course-items/update/${id}`,
    payload
  );
};

export const deleteCourseItem = (id) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/course-items/delete/${id}`);
};

export const createQuestion = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/course-items/question`,
    payload
  );
};

export const updateQuestion = (id, formData) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/course-items/question/update/${id}`,
    formData
  );
};

export const deleteQuestion = (id) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/course-items/question/delete/${id}`
  );
};

export const sendOrDownloadCertificate = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/course-items/send-certificate`,
    payload,
    { responseType: "blob" }
  );
};
