import { create, getVideos, remove, update } from "@/services/videoService";
import { cloneDeep, concat, differenceWith, filter, isEqual } from "lodash";

const state = {
  videos: [],
  videosLoading: false,
};

const getters = {
  getVideos: (state) => state.videos,
  getVideosLoading: (state) => state.videosLoading,
};

const mutations = {
  setVideos(state, payload) {
    state.videos = payload;
  },
  setVideosLoading(state, payload) {
    state.videosLoading = payload;
  },
};

const actions = {
  setVideosLoading({ commit }, payload) {
    commit("setVideosLoading", payload);
  },

  async setVideos({ commit }, payload) {
    try {
      const params = {};

      if (_.get(payload, "language")) {
        _.assign(params, { language: _.get(payload, "language") });
      }

      commit("setVideosLoading", true);
      const { data } = await getVideos(params);

      commit("setVideos", data);
    } catch (error) {
      console.error(error);
    }

    commit("setVideosLoading", false);
  },

  async createVideo({ commit, state }, payload) {
    try {
      commit("setVideosLoading", true);
      const { data } = await create(payload);

      state.videos = concat(differenceWith(state.videos, data, isEqual), data);
    } catch (error) {
      console.error(error);
    }

    commit("setVideosLoading", false);
  },

  async updateVideo({ commit, state }, { id, payload }) {
    try {
      commit("setVideosLoading", true);
      const { data } = await update(id, payload);

      state.videos = cloneDeep(state.videos).map((video) => {
        return video.id == id ? data : video;
      });
    } catch (error) {
      console.error(error);
    }

    commit("setVideosLoading", false);
  },

  async deleteVideo({ commit, state }, id) {
    try {
      commit("setVideosLoading", true);
      await remove(id);

      state.videos = filter(
        cloneDeep(state.videos),
        (video) => video.id !== id
      );
    } catch (error) {
      console.error(error);
    }

    commit("setVideosLoading", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
