import { getClients } from "@/services/clientService";

const state = {
  clients: [],
  clientsLoading: false,
};

const getters = {
  getClients: (state) => state.clients,
  getClientsLoading: (state) => state.clientsLoading,
};

const mutations = {
  setClients(state, payload) {
    state.clients = payload;
  },
  setClientsLoading(state, payload) {
    state.clientsLoading = payload;
  },
};

const actions = {
  setClientsLoading({ commit }, payload) {
    commit("setClientsLoading", payload);
  },

  async setClients({ commit }) {
    try {
      commit("setClientsLoading", true);
      const { data } = await getClients();

      commit("setClients", data);
    } catch (error) {
      console.error(error);
    }

    commit("setClientsLoading", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
