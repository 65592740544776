import { objToUrlParam } from "@/shared/utils";
import axios from "axios";

export const getSignUpReport = (payload) => {
  return axios.get(`${process.env.VUE_APP_API_URL}/sign-up/get-report`, {
    params: payload,
  });
};

export const getSignup = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/sign-up/get-all?${objToUrlParam(payload)}`
  );
};

export const storeSignup = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/sign-up/save-signup`,
    payload
  );
};

export const deleteSignup = (id) => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/sign-up/delete/${id}`);
};
