import {
  createCourseItem,
  createQuestion,
  deleteCourse,
  deleteCourseItem,
  deleteQuestion,
  editCourse,
  getAllCourse,
  getCourseItemQuestions,
  getCourseItems,
  getCourseParentItems,
  saveCourse,
  updateCourseItem,
  updateQuestion,
} from "@/services/courseService";
import {
  insertDataToState,
  removeDataFromState,
  updateDataInState,
} from "@/shared/utils";
import { assign, get, orderBy } from "lodash";

const state = {
  courses: null,
  coursesWithScorm: null,
  coursesPage: 1,
  coursesTotal: 0,
  coursesPagesNumber: 0,
  coursesLoading: false,
  courseItems: null,
  courseItemsLoading: false,
  courseParentItems: null,
  courseParentItemsLoading: false,
  courseItemQuestions: null,
  courseItemQuestionsLoading: false,
};

const getters = {
  getCourses: (state) => state.courses,
  getCoursesPage: (state) => state.coursesPage,
  getCoursesTotal: (state) => state.coursesTotal,
  getCoursesPagesNumber: (state) => state.coursesPagesNumber,
  getCoursesLoading: (state) => state.coursesLoading,
  getCourseItems: (state) => state.courseItems,
  getCourseItemsLoading: (state) => state.courseItemsLoading,
  getCourseParentItems: (state) => state.courseParentItems,
  getCourseParentItemsLoading: (state) => state.courseParentItemsLoading,
  getCourseItemQuestions: (state) => state.courseItemQuestions,
  getCourseItemQuestionsLoading: (state) => state.courseItemQuestionsLoading,
};

const mutations = {
  setCourses(state, payload) {
    state.courses = get(payload, "data");
    state.coursesPage = get(payload, "current_page");
    state.coursesTotal = get(payload, "total");
    state.coursesPagesNumber = get(payload, "last_page");
  },
  setCoursesLoading(state, payload) {
    state.coursesLoading = payload;
  },
  setCourseItems(state, payload) {
    state.courseItems = payload;
  },
  setCourseItemsLoading(state, payload) {
    state.courseItemsLoading = payload;
  },
  setCourseParentItems(state, payload) {
    state.courseParentItems = payload;
  },
  setCourseParentItemsLoading(state, payload) {
    state.courseParentItemsLoading = payload;
  },
  setCourseItemQuestions(state, payload) {
    state.courseItemQuestions = payload;
  },
  setCourseItemQuestionsLoading(state, payload) {
    state.courseItemQuestionsLoading = payload;
  },
};

const actions = {
  async createCourse({ commit }, payload) {
    try {
      return await saveCourse(payload);
    } catch (error) {
      console.error(error);
    }
  },

  async setCourses({ commit }, payload) {
    try {
      commit("setCoursesLoading", true);
      const { data } = await getAllCourse({
        page: get(payload, "page", 1),
        perPage: get(payload, "perPage", 10),
      });
      commit("setCourses", data);
    } catch (error) {
      console.error(error);
    }

    commit("setCoursesLoading", false);
  },

  async editCourses({ commit }, formData) {
    const { data } = await editCourse(formData);
    commit("updateCourseLookup", { payload: data, isScorm: false });
  },

  async deleteCourse({ commit }, id) {
    try {
      await deleteCourse(id);
    } catch (error) {
      console.error(error);
    }
  },

  setCourseItemsLoading({ commit }, payload) {
    commit("setCourseItemsLoading", payload);
  },

  async setCourseItems({ commit }, payload) {
    const params = {
      id: get(payload, "id"),
    };

    if (get(payload, "parent")) {
      assign(params, { parent: get(payload, "parent") });
    }

    try {
      commit("setCourseItemsLoading", true);
      const { data } = await getCourseItems(params);

      commit("setCourseItems", data);
    } catch (error) {
      console.error(error);
    }

    commit("setCourseItemsLoading", false);
  },

  setCourseParentItemsLoading({ commit }, payload) {
    commit("setCourseParentItemsLoading", payload);
  },

  async setCourseParentItems({ commit }, payload) {
    const params = {
      id: get(payload, "id"),
    };

    try {
      commit("setCourseParentItemsLoading", true);
      const { data } = await getCourseParentItems(params);

      commit("setCourseParentItems", data);
    } catch (error) {
      console.error(error);
    }

    commit("setCourseParentItemsLoading", false);
  },

  async createCourseItem({ commit }, payload) {
    try {
      await createCourseItem(payload);
    } catch (error) {
      console.error(error);
    }
  },

  async updateCourseItem({ dispatch }, { id, payload }) {
    try {
      await updateCourseItem(id, payload);
    } catch (error) {
      console.error(error);
    }
  },

  async deleteCourseItem({ commit }, id) {
    try {
      await deleteCourseItem(id);
    } catch (error) {
      console.error(error);
    }
  },

  setCourseItemQuestionsLoading({ commit }, payload) {
    commit("setCourseItemQuestionsLoading", payload);
  },

  async setCourseItemQuestions({ commit }, payload) {
    const params = {
      id: get(payload, "id"),
      item_id: get(payload, "itemId"),
    };

    try {
      commit("setCourseItemQuestionsLoading", true);
      const { data } = await getCourseItemQuestions(params);

      commit("setCourseItemQuestions", data);
    } catch (error) {
      console.error(error);
    }

    commit("setCourseItemQuestionsLoading", false);
  },

  async createQuestion({ commit, state, dispatch }, payload) {
    try {
      commit("setCourseItemQuestionsLoading", true);
      const { data } = await createQuestion(payload);

      state.courseItemQuestions = orderBy(
        insertDataToState(state.courseItemQuestions, data),
        ["order"]
      );

      return data;
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }

    commit("setCourseItemQuestionsLoading", false);
  },

  async updateQuestion({ commit, state }, { id, formData }) {
    try {
      commit("setCourseItemQuestionsLoading", true);
      const { data } = await updateQuestion(id, formData);

      state.courseItemQuestions = orderBy(
        updateDataInState(state.courseItemQuestions, data),
        ["order"]
      );

      return data;
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }

    commit("setCourseItemQuestionsLoading", false);
  },

  async deleteQuestion({ commit, state }, id) {
    try {
      commit("setCourseItemQuestionsLoading", true);
      await deleteQuestion(id);

      state.courseItemQuestions = removeDataFromState(
        state.courseItemQuestions,
        id
      );
    } catch (error) {
      console.error(error);
    }

    commit("setCourseItemQuestionsLoading", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
