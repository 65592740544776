import store from "@/store";

export default async (to, from, next) => {
  await store.dispatch("setStudentsCourses");

  await store.dispatch("setCourseLookup");
  await store.dispatch("setStudents", {
    perPage: -1,
  });

  if (!Object.keys(store.getters.getCustomerCourses).length) {
    await store.dispatch("setCustomerCourses");
  }
  next();
};
