import axios from "axios";

export const getAll = payload => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/student-courses/get-all-grouped`,
    { params: payload }
  );
};

export const getEnrolledCourses = payload => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/student-courses/enrolled-courses`,
    { params: payload }
  );
};

export const deleteStudentCourse = payload => {
  return axios.delete(
    `${process.env.VUE_APP_API_URL}/student-courses/destroy`,
    { params: payload }
  );
};

export const addStudentCourse = payload => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/student-courses/store`,
    payload
  );
};
