import { deleteSignup, getSignup } from "@/services/signUpService";
import * as _ from "lodash";

const state = {
  signUp: "",
};

const getters = {
  getSignup: (state) => state.signUp,
};

const mutations = {
  setSignup(state, payload) {
    state.signUp = payload;
  },

  deleteSignup(state, id) {
    const clonedSignup = _.cloneDeep(state.signUp);
    const index = _.findIndex(clonedSignup, (signup) => signup.id === id);
    clonedSignup.splice(index, 1);
    state.signUp = clonedSignup;
  },
};

const actions = {
  async setSignup({ commit }, payload) {
    const { data } = await getSignup({
      formList: _.get(payload, "formList", ""),
    });

    commit("setSignup", data);
  },
  async destroySignup({ commit }, id) {
    await deleteSignup(id);
    commit("deleteSignup", id);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
