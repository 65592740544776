import { getCertificates } from "@/services/certificateService";
import * as _ from "lodash";

const state = {
  certificates: []
};

const getters = {
  getCertificates: state => state.certificates
};

const mutations = {
  setCertificates(state, payload) {
    state.certificates = payload;
  }
};

const actions = {
  async setCertificates({ commit }) {
    const { data } = await getCertificates();
    commit("setCertificates", data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
