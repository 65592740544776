import { objToUrlParam } from "@/shared/utils";
import axios from "axios";

export const getAllCourse = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/course-lookup/get-all?${objToUrlParam(
      payload
    )}`
  );
};

export const getCourseLookupFilter = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/course-lookup/filter?${objToUrlParam(
      payload
    )}`
  );
};

export const destroy = (id) => {
  return axios.delete(
    `${process.env.VUE_APP_API_URL}/course-lookup/delete/${id}`
  );
};

export const cloneCourse = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/course-lookup/clone`,
    payload
  );
};

export const exportCourseParticipants = (payload) => {
  return axios.post(
    `${
      process.env.VUE_APP_API_URL
    }/course-lookup/export-participants?${objToUrlParam(payload)}`,
    {
      headers: {
        "Content-Disposition": `attachment; filename=course.csv`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer",
    }
  );
};

export const exportCourseMail = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/course-lookup/export-mail?${objToUrlParam(
      payload
    )}`
  );
};

export const exportCourseText = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/course-lookup/export-text?${objToUrlParam(
      payload
    )}`
  );
};
