import axios from "axios";

export const getAll = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/franchises`);
};

export const getCustomerOptions = (id) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/franchises/${id}/customer-options`
  );
};

export const create = (payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/franchises`, payload);
};

export const update = (id, payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/franchises/${id}`, payload);
};

export const updateCustomers = (id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/franchises/${id}/customers`,
    payload
  );
};

export const destroy = (id) => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/franchises/${id}`);
};

export const checkUsername = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/franchises/check-username`,
    payload
  );
};
