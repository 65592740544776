import { getAll } from "@/services/answerTypeService";

const state = {
  answerTypes: [],
  answerTypesLoading: false,
};

const getters = {
  getAnswerTypes: (state) => state.answerTypes,
  getAnswerTypesLoading: (state) => state.answerTypesLoading,
};

const mutations = {
  setAnswerTypes(state, payload) {
    state.answerTypes = payload;
  },
  setAnswerTypesLoading(state, payload) {
    state.answerTypesLoading = payload;
  },
};

const actions = {
  setAnswerTypesLoading({ commit }, payload) {
    commit("setAnswerTypesLoading", payload);
  },
  async setAnswerTypes({ commit }) {
    try {
      commit("setAnswerTypesLoading", true);
      const { data } = await getAll();
      commit("setAnswerTypes", data);
    } catch (error) {
      console.error(error);
    }

    commit("setAnswerTypesLoading", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
