import store from "@/store";
import { get } from "lodash";

export default (to, from, next) => {
  if (!get(store, "getters.getToken", null)) {
    store.dispatch("signOut");
    next("/auth/sign-in");
  }

  next();
};
