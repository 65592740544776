<template>
  <router-view />
</template>

<script>
import { objToUrlParam } from "@/shared/utils";
import { get } from "lodash";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Admin",
      // all titles will be injected into this template
      titleTemplate: "%s | Admin",
    };
  },
  async created() {
    await this.setDomain();

    const favicon = document.getElementById("appFavicon");
    favicon.href = `${
      process.env.VUE_APP_API_URL
    }/commands/thumbnail?${objToUrlParam({
      resource: get(this.getDomain, "logo_icon"),
      force: 1,
    })}`;

    const loaderLogo = document.getElementById("appLoaderLogo");
    loaderLogo.src = `${
      process.env.VUE_APP_API_URL
    }/commands/thumbnail?${objToUrlParam({
      resource: get(this.getDomain, "logo_admin"),
      force: 1,
    })}`;

    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    mq.addEventListener("change", (e) => {
      console.log(mq);
      this.$vuetify.theme.dark = false; // Set to mq.matches if we want to base it on os theme
    });
  },
  computed: {
    ...mapGetters(["getDomain"]),
  },

  methods: {
    ...mapActions(["setDomain"]),
  },
};
</script>
