import { objToUrlParam } from "@/shared/utils";
import axios from "axios";

export const getVideos = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/videos?${objToUrlParam(payload)}`
  );
};

export const create = (payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/videos`, payload);
};

export const update = (id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/videos/update/${id}`,
    payload
  );
};

export const remove = (id) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/videos/delete/${id}`);
};
