export const MODEL_ACTION = {
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete",
};

export const FIELD_TYPES = {
  TEXT: "text",
  EMAIL: "email",
  NUMBER: "number",
  PASSWORD: "password",
  SELECT: "select",
  TEXTAREA: "textarea",
  FILE: "file",
  IMAGE: "image",
  PDF: "pdf",
  CHECKBOX: "checkbox",
  SWITCH: "switch",
  DATETIME: "datetime",
  DATE: "date",
  TIME: "time",
  HTML: "html",
  UUID: "uuid",
  SPECIFIC_FILE: "specific_file_output",
};

export const FILE_FIELD_TYPES = ["file", "pdf"];

export const FILE_ACCEPTS = {
  FILE: "*",
  IMAGE: "image/png,image/jpeg,image/webp,image/gif,image/svg+xml",
  PDF: "application/pdf",
};

export const FILE_INPUT_ICON = {
  FILE: "mdi-paperclip",
  IMAGE: "mdi-image-outline",
  PDF: "mdi-file-pdf-box",
};

export const RELATION_TYPES = {
  ONE_TO_ONE: "One to one",
  ONE_TO_MANY: "One to many (read only)",
  MANY_TO_MANY: "Many to many",
  MANY_TO_MANY_COMMA: "Many to many (comma separated)",
  ONE_TO_MANY_IMAGES: "One to many (image upload)",
};

export const USER_TYPES = {
  ADMIN: 1,
};

export const LANGUAGES = [
  {
    text: "English",
    value: "EN",
  },
  {
    text: "Spanish",
    value: "ES",
  },
  {
    text: "French",
    value: "FR",
  },
  {
    text: "German",
    value: "DE",
  },
  {
    text: "Dutch",
    value: "NL",
  },
];

export const CERTIFICATE_EXPIRE_YEARS = [
  {
    text: "One year",
    value: 1,
  },
  {
    text: "Two years",
    value: 2,
  },
  {
    text: "Three years",
    value: 3,
  },
];

export const ITEM_TYPES = {
  QUESTION: "question",
  TEXT: "text",
  MOVIE: "movie",
  TEST: "test",
  RESULT_PAGE_SUCCESS: "result_page_success",
  INTRODUCTION: "introduction",
  RESULT_PAGE_FAIL: "result_page_fail",
  VIDEO_QUESTION_INPUT: "video_question_input",
};

export const ANSWER_TYPES = {
  TEXT: 1,
  IMAGE: 2,
};

export const CUSTOMER_ACTION = {
  MAIL: "mail",
  MANUAL: "manual",
};
