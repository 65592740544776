import {
  cloneCourse,
  destroy,
  exportCourseMail,
  exportCourseParticipants,
  exportCourseText,
  getAllCourse,
} from "@/services/courseLookup";
import { insertDataToState } from "@/shared/utils";
import _ from "lodash";

const state = {
  courseLookup: null,
  courseLookupLoading: false,
};

const getters = {
  getCourseLookup: (state) => state.courseLookup,
  getCourseLookupLoading: (state) => state.courseLookupLoading,
};

const mutations = {
  setCourseLookup(state, payload) {
    state.courseLookup = _.sortBy(payload, "title");
  },
  setCourseLookupLoading(state, payload) {
    state.courseLookupLoading = payload;
  },

  deleteCourseLookup(state, { id }) {
    const clonedLookup = _.cloneDeep(state.courseLookup);
    const index = _.findIndex(clonedLookup, (course) => course.id === id);
    clonedLookup.splice(index, 1);
    state.courseLookup = clonedLookup;
  },

  updateCourseLookup(state, { payload, isScorm }) {
    const clonedLookup = _.cloneDeep(state.courseLookup);
    const index = _.findIndex(clonedLookup, (course) =>
      isScorm
        ? _.get(course, "scorm_id") == payload.id && course.is_scorm == isScorm
        : _.get(course, "course_id") == payload.id && course.is_scorm == isScorm
    );

    const newData = {
      id: _.get(clonedLookup[index], "id"),
      title: _.get(payload, "title", _.get(payload, "description")),
      is_scorm: isScorm,
      passing_score: isScorm
        ? _.get(payload, "scorm_sco.passing_score_percent")
        : _.get(payload, "passing_score"),
      max_course_try: isScorm
        ? _.get(payload, "scorm_sco.max_course_try")
        : _.get(payload, "max_course_try"),
    };

    clonedLookup.splice(
      index,
      1,
      isScorm
        ? {
            scorm_id: payload.id,
            scorm: payload,
            ...newData,
          }
        : {
            course_id: payload.id,
            course: payload,
            ...newData,
          }
    );

    state.courseLookup = clonedLookup;
  },
};

const actions = {
  async setCourseLookup({ commit }, payload) {
    commit("setCourseLookupLoading", true);
    try {
      const { data } = await getAllCourse({
        formList: _.get(payload, "formList", ""),
      });

      commit("setCourseLookup", data);
      commit("setCourseLookupLoading", false);

      return data;
    } catch (error) {
      console.error(error);
      commit("setCourseLookupLoading", false);
    }
  },

  async deleteCourseLookup({ commit }, id) {
    try {
      await destroy(id);
      commit("deleteCourseLookup", { id });
    } catch (error) {
      console.error(error);
    }
  },

  async cloneCourse({ commit, state, dispatch }, payload) {
    try {
      commit("setCourseLookupLoading", true);

      const { data } = await cloneCourse(payload);
      state.courseLookup = _.sortBy(
        insertDataToState(state.courseLookup, data),
        ["title"]
      );

      commit("setCourseLookupLoading", false);

      return data;
    } catch (error) {
      console.error("DEBUG: error", error);
      commit("setCourseLookupLoading", false);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }
  },

  async exportCourseParticipants({ commit, dispatch }, payload) {
    try {
      commit("setCourseLookupLoading", true);

      const { data } = await exportCourseParticipants(payload);
      commit("setCourseLookupLoading", false);

      return data;
    } catch (error) {
      console.error("DEBUG: error", error);
      commit("setCourseLookupLoading", false);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }
  },

  async exportCourseMail({ commit, dispatch }, payload) {
    try {
      commit("setCourseLookupLoading", true);

      const { data } = await exportCourseMail(payload);
      commit("setCourseLookupLoading", false);

      return data;
    } catch (error) {
      console.error("DEBUG: error", error);
      commit("setCourseLookupLoading", false);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }
  },

  async exportCourseText({ commit, dispatch }, payload) {
    try {
      commit("setCourseLookupLoading", true);

      const { data } = await exportCourseText(payload);
      commit("setCourseLookupLoading", false);

      return data;
    } catch (error) {
      console.error("DEBUG: error", error);
      commit("setCourseLookupLoading", false);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
