import { getAll } from "@/services/itemTypeService";

const state = {
  itemTypes: [],
  itemTypesLoading: false
};

const getters = {
  getItemTypes: state => state.itemTypes,
  getItemTypesLoading: state => state.itemTypesLoading
};

const mutations = {
  setItemTypes(state, payload) {
    state.itemTypes = payload;
  },
  setItemTypesLoading(state, payload) {
    state.itemTypesLoading = payload;
  }
};

const actions = {
  setItemTypesLoading({ commit }, payload) {
    commit("setItemTypesLoading", payload);
  },
  async setItemTypes({ commit }) {
    try {
      commit("setItemTypesLoading", true);
      const { data } = await getAll();
      commit("setItemTypes", data);
    } catch (error) {
      console.error(error);
    }

    commit("setItemTypesLoading", false);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
