import axios from "axios";

export const getAll = payload => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/customer-courses/get-all-grouped`,
    { params: payload }
  );
};

export const deleteCustomerCourse = payload => {
  return axios.delete(
    `${process.env.VUE_APP_API_URL}/customer-courses/destroy`,
    { params: payload }
  );
};

export const addCustomerCourse = payload => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/customer-courses/store`,
    payload
  );
};
