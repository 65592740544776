import {
  getAll,
  addCustomerCourse,
  deleteCustomerCourse
} from "@/services/customerCoursesService";
import _ from "lodash";

const state = {
  customerCourses: []
};

const getters = {
  getCustomerCourses: state => state.customerCourses
};

const mutations = {
  setCustomerCourses(state, payload) {
    state.customerCourses = payload;
  }
};

const actions = {
  async setCustomerCourses({ commit }) {
    const { data } = await getAll();
    commit("setCustomerCourses", data);
  },

  async deleteCustomerCourse({ commit, dispatch }, payload) {
    await deleteCustomerCourse(payload);
    dispatch("setCustomerCourses");
  },

  async addCustomerCourse({ commit, dispatch }, payload) {
    await addCustomerCourse(payload);
    dispatch("setCustomerCourses");
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
