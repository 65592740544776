import axios from "axios";

export const getCertificates = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/certificates/get-all-certs`);
};

export const getCertificate = payload => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/certificates/get-by-id/${payload}`,
    { responseType: "blob" }
  );
};
