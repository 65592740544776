import {
  getAll,
  deleteStudentCourse,
  addStudentCourse
} from "@/services/studentCoursesService";
import _ from "lodash";

const state = {
  studentsCourses: []
};

const getters = {
  getStudentsCourses: state => state.studentsCourses
};

const mutations = {
  setStudentsCourses(state, payload) {
    state.studentsCourses = payload;
  }
};

const actions = {
  async setStudentsCourses({ commit }) {
    const { data } = await getAll();
    commit("setStudentsCourses", data);
  },

  async deleteStudentCourse({ commit, dispatch }, payload) {
    await deleteStudentCourse(payload);
    dispatch("setStudentsCourses");
  },

  async addStudentCourse({ commit, dispatch }, payload) {
    await addStudentCourse(payload);
    dispatch("setStudentsCourses");
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
