import { getAll, updateVideoInput } from "@/services/videoInputService";

const state = {
  videoInputs: [],
  videoInputsLoading: false
};

const getters = {
  getVideoInputs: state => state.videoInputs,
  getVideoInputsLoading: state => state.videoInputsLoading
};

const mutations = {
  setVideoInputs(state, payload) {
    state.videoInputs = payload;
  },
  setVideoInputsLoading(state, payload) {
    state.videoInputsLoading = payload;
  }
};

const actions = {
  setVideoInputsLoading({ commit }, payload) {
    commit("setVideoInputsLoading", payload);
  },

  async setVideoInputs({ commit }) {
    try {
      commit("setVideoInputsLoading", true);
      const { data } = await getAll();

      commit("setVideoInputs", data);
    } catch (error) {
      console.error(error);
    }

    commit("setVideoInputsLoading", false);
  },

  async updateVideoInput({ dispatch }, { id, payload }) {
    try {
      const { data } = await updateVideoInput(id, payload);

      await dispatch("setVideoInputs");

      return data;
    } catch (error) {
      console.error(error);
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
