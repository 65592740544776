import axios from "axios";

export const getAll = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/video-inputs`);
};

export const updateVideoInput = (id, payload) => {
  return axios.put(
    `${process.env.VUE_APP_API_URL}/video-inputs/${id}`,
    payload
  );
};
