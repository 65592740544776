import {
  create,
  destroy,
  exportStudentLoginDetails,
  getAll,
  getAllStudentCustomers,
  getAvailable,
  getById,
  importFromCsv,
  sendMutipleStudentLoginDetails,
  sendStudentLoginDetails,
  toggleDisabledStatus,
} from "@/services/studentService";
import { removeDataFromState } from "@/shared/utils";
import { get, size } from "lodash";

const state = {
  students: [],
  student: {},
  studentsPage: 1,
  studentsTotal: 0,
  studentsPagesNumber: 0,
  studentsLoading: false,
  availableStudents: [],
  studentCustomers: [],
  studentCustomersPage: 1,
  studentCustomersTotal: 0,
  studentCustomersPagesNumber: 0,
};

const getters = {
  getStudents: (state) => state.students,
  getStudent: (state) => state.student,
  getStudentsPage: (state) => state.studentsPage,
  getStudentsTotal: (state) => state.studentsTotal,
  getStudentsPagesNumber: (state) => state.studentsPagesNumber,
  getStudentsLoading: (state) => state.studentsLoading,
  getAvailableStudents: (state) => state.availableStudents,
  getStudentCustomers: (state) => state.studentCustomers,
  getStudentCustomersPage: (state) => state.studentCustomersPage,
  getStudentCustomersTotal: (state) => state.studentCustomersTotal,
  getStudentCustomersPagesNumber: (state) => state.studentCustomersPagesNumber,
};

const mutations = {
  setStudents(state, payload) {
    state.students = get(payload, "data");
    state.studentsPage = get(payload, "current_page");
    state.studentsTotal = get(payload, "total");
    state.studentsPagesNumber = get(payload, "last_page");
  },
  setStudentsLoading(state, payload) {
    state.studentsLoading = payload;
  },
  setAvailableStudents(state, payload) {
    state.availableStudents = payload;
  },
  setStudent(state, payload) {
    state.student = payload;
  },
  setStudentCustomers(state, payload) {
    state.studentCustomers = get(payload, "data");
    state.studentCustomersPage = get(payload, "current_page");
    state.studentCustomersTotal = get(payload, "total");
    state.studentCustomersPagesNumber = get(payload, "last_page");
  },
};

const actions = {
  async setStudents({ commit }, payload) {
    try {
      commit("setStudentsLoading", true);

      const { data } = await getAll({
        page: get(payload, "page", 1),
        search: get(payload, "search", ""),
        perPage: get(payload, "perPage", 25),
        formList: get(payload, "formList", ""),
      });

      commit("setStudents", data);
    } catch (error) {
      console.error(error);
    }

    commit("setStudentsLoading", false);
  },
  async setStudentCustomers({ commit }, payload) {
    try {
      commit("setStudentsLoading", true);

      const { data } = await getAllStudentCustomers({
        page: get(payload, "page", 1),
        search: get(payload, "search", ""),
        perPage: get(payload, "perPage", 10),
      });

      commit("setStudentCustomers", data);
    } catch (error) {
      console.error(error);
    }

    commit("setStudentsLoading", false);
  },
  async setStudent({ commit }, id) {
    try {
      commit("setStudentsLoading", true);
      const { data } = await getById(id);

      commit("setStudent", data);
    } catch (error) {
      console.error(error);
    }

    commit("setStudentsLoading", false);
  },
  async deleteStudent({ commit, state, dispatch }, id) {
    try {
      commit("setStudentsLoading", true);
      await destroy(id);

      state.students = removeDataFromState(state.students, id);
      state.studentsTotal = size(state.students);

      dispatch("showSnackbar", "Student has been removed.");
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }

    commit("setStudentsLoading", false);
  },
  async setAvailableStudents({ commit }) {
    try {
      const { data } = await getAvailable();

      commit("setAvailableStudents", data);
    } catch (error) {
      console.error(error);
    }
  },
  async createStudent({ dispatch }, payload) {
    try {
      const { data } = await create(payload);

      return data;
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }
  },
  async importFromCsv({ dispatch }, payload) {
    try {
      const { data } = await importFromCsv(payload);

      return data;
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }
  },
  async toggleDisabledStatus({ commit, dispatch, state }, { id, payload }) {
    try {
      const { data } = await toggleDisabledStatus(id, payload);

      state.students = removeDataFromState(state.students, id);
      state.studentsTotal = size(state.students);

      commit("removeCustomerStudent", { payload: data });
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }
  },
  async sendStudentLoginDetails({ commit, dispatch }, id) {
    commit("setStudentsLoading", true);

    try {
      await sendStudentLoginDetails(id);
      dispatch("showSnackbar", "Email has been sent.");
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }

    commit("setStudentsLoading", false);
  },
  async sendMutipleStudentLoginDetails({ commit, dispatch }, ids) {
    commit("setStudentsLoading", true);

    try {
      await sendMutipleStudentLoginDetails(ids);
      dispatch("showSnackbar", "Emails has been sent.");
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }

    commit("setStudentsLoading", false);
  },
  async exportStudentLoginDetails({ commit, dispatch }, id) {
    try {
      const { data } = await exportStudentLoginDetails(id);

      return data;
    } catch (error) {
      console.error("DEBUG: error", error);
      dispatch("showSnackbar", "Oops, something went wrong!");
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
