import { editScorm, getScorm, uploadScorm } from "@/services/scormService";

const state = {
  scorm: null,
  scormLoading: false,
};

const getters = {
  getScorm: (state) => state.scorm,
  getScormLoading: (state) => state.scormLoading,
};

const mutations = {
  setScorm(state, payload) {
    state.scorm = payload;
  },
  setScormLoading(state, payload) {
    state.scormLoading = payload;
  },
};

const actions = {
  setScormLoading({ commit }, payload) {
    commit("setScormLoading", payload);
  },

  async uploadScormFile({ commit }, payload) {
    try {
      return await uploadScorm(payload);
    } catch (error) {
      console.error(error);
    }
  },
  async editScorm({ commit }, payload) {
    try {
      commit("setScormLoading", true);
      const { data } = await editScorm(payload);
      commit("updateCourseLookup", { payload: data, isScorm: true });
    } catch (error) {
      console.error(error);
    }

    commit("setScormLoading", false);
  },
  async setScorm({ commit }, payload) {
    try {
      commit("setScormLoading", true);
      const { data } = await getScorm(payload);
      commit("setScorm", data);
    } catch (error) {
      console.error(error);
    }

    commit("setScormLoading", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
