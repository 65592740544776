import store from "@/store";

export default async (to, from, next) => {
  await store.dispatch("setCourses", {
    page: 1,
    perPage: 10,
  });

  await store.dispatch("setCourseLookup");
  await store.dispatch("setCertificates");
  await store.dispatch("setSignup");

  next();
};

export const scormData = async (to, from, next) => {
  await store.dispatch("setScorm", {
    id: _.get(to, "params.id"),
  });

  next();
};

export const courseItemQuestions = async (to, from, next) => {
  await store.dispatch("setCourseItems", {
    id: _.get(to, "params.id"),
  });

  await store.dispatch("setCourseItemQuestions", {
    id: _.get(to, "params.id"),
    itemId: _.get(to, "params.itemId"),
  });

  await store.dispatch("setCourseLookup");

  next();
};
