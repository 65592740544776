import { checkDomain } from "@/services/domainService";

const state = {
  domain: {},
  domainLoading: false,
};

const getters = {
  getDomain: (state) => state.domain,
  getDomainLoading: (state) => state.domainLoading,
};

const mutations = {
  setDomain(state, payload) {
    state.domain = payload;
  },
  setDomainLoading(state, payload) {
    state.domainLoading = payload;
  },
};

const actions = {
  setDomainLoading({ commit }, payload) {
    commit("setDomainLoading", payload);
  },

  async setDomain({ commit }) {
    try {
      commit("setDomainLoading", true);

      const { data } = await checkDomain(window.location.hostname);

      commit("setDomain", data);
    } catch (error) {
      console.error(error);
    }

    commit("setDomainLoading", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
