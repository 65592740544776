import { getNotifications } from "@/services/notificationService";

const state = {
  notifications: [],
  notificationsLoading: false,
};

const getters = {
  getNotifications: (state) => state.notifications,
  getNotificationsLoading: (state) => state.notificationsLoading,
};

const mutations = {
  setNotifications(state, payload) {
    state.notifications = payload;
  },
  setNotificationsLoading(state, payload) {
    state.notificationsLoading = payload;
  },
};

const actions = {
  setNotificationsLoading({ commit }, payload) {
    commit("setNotificationsLoading", payload);
  },

  async setNotifications({ commit }) {
    try {
      commit("setNotificationsLoading", true);
      const { data } = await getNotifications();

      commit("setNotifications", data);
    } catch (error) {
      console.error(error);
    }

    commit("setNotificationsLoading", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
