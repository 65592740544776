import { objToUrlParam } from "@/shared/utils";
import axios from "axios";

export const getAll = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/student?${objToUrlParam(payload)}`
  );
};

export const getStudentsFilter = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/student/filter?${objToUrlParam(payload)}`
  );
};

export const getAllStudentCustomers = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/student/get-customers?${objToUrlParam(
      payload
    )}`
  );
};

export const getById = (id) => {
  return axios.get(`${process.env.VUE_APP_API_URL}/student/${id}`);
};

export const create = (payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/student`, payload);
};

export const importFromCsv = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/student/import-from-csv`,
    payload
  );
};

export const edit = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/student/update/${payload.id}`,
    payload
  );
};

export const destroy = (id) => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/student/delete/${id}`);
};

export const getAvailable = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/student/get-available`);
};

export const toggleDisabledStatus = (id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/student/toggle-disabled/${id}`,
    payload
  );
};

export const sendStudentLoginDetails = (id) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/student/send-login-details/${id}`
  );
};

export const sendMutipleStudentLoginDetails = (ids) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/student/send-multiple-login-details`,
    ids
  );
};

export const exportStudentLoginDetails = (id) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/student/export-login-details/${id}`,
    {
      headers: {
        "Content-Disposition": `attachment;`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer",
    }
  );
};

export const getStudentProgressReport = (id) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/student/progress-report/${id}`
  );
};

export const checkUsername = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/student/check-username?${objToUrlParam(
      payload
    )}`
  );
};
