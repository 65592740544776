import axios from "axios";
import { get } from "lodash";

export const getScorm = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/scorm/get-scorm/${get(payload, "id")}`
  );
};

export const uploadScorm = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/scorm/file-upload`,
    payload
  );
};

export const editScorm = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/scorm/edit-course/${payload.get("id")}`,
    payload
  );
};

export const deleteScorm = (id) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/scorm/delete-course/${id}`);
};

export const sendOrDownloadScormCertificate = (payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/scorm/send-certificate`,
    payload,
    { responseType: "blob" }
  );
};
