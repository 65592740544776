import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import answerTypes from "./modules/answerTypes";
import auth from "./modules/auth";
import certificates from "./modules/certificates";
import clients from "./modules/clients";
import compactSidebar from "./modules/compactSidebar";
import courseLookup from "./modules/courseLookup";
import courses from "./modules/courses";
import customers from "./modules/customer";
import customerCourses from "./modules/customerCourse";
import domain from "./modules/domain";
import events from "./modules/events";
import franchises from "./modules/franchises";
import itemTypes from "./modules/itemTypes";
import languages from "./modules/languages";
import largeSidebar from "./modules/largeSidebar";
import models from "./modules/models";
import notifications from "./modules/notifications";
import pages from "./modules/pages";
import scorms from "./modules/scorms";
import signUp from "./modules/signUp";
import snackbar from "./modules/snackbar";
import students from "./modules/student";
import studentCourses from "./modules/studentCourses";
import themeConfig from "./modules/themeConfig";
import userTypes from "./modules/userTypes";
import verticalSidebar from "./modules/verticalSidebar";
import videoInputs from "./modules/videoInputs";
import videos from "./modules/videos";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    auth: {
      loggedInUser: state.auth.loggedInUser,
      token: state.auth.token,
    },
  }),
  key: "eyestone-admin-v2",
});

export default new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,
    themeConfig,
    auth,
    verticalSidebar,
    userTypes,
    pages,
    models,
    snackbar,
    events,
    courses,
    students,
    studentCourses,
    customers,
    customerCourses,
    scorms,
    courseLookup,
    videoInputs,
    signUp,
    itemTypes,
    certificates,
    videos,
    languages,
    answerTypes,
    notifications,
    clients,
    domain,
    franchises,
  },
  plugins: [vuexLocal.plugin],
});
