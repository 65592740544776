import { objToUrlParam } from "@/shared/utils";
import axios from "axios";
import { set } from "lodash";
import { ref } from "vue";

export const getAll = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/events?${objToUrlParam(payload)}`
  );
};

export const getTypes = () => {
  return axios.get(`${process.env.VUE_APP_API_URL}/events/types`);
};

export const create = (payload) => {
  set(
    payload,
    "window_dimensions",
    `${ref(window.innerWidth)}x${ref(window.innerHeight)}`
  );

  return axios.post(`${process.env.VUE_APP_API_URL}/events`, payload);
};
