import store from "@/store";

export default async (to, from, next) => {
  next();
};

export const customerStudents = async (to, from, next) => {
  await store.dispatch("setCustomer", _.get(to, "params.id"));

  await store.dispatch("setCustomerStudents", {
    id: _.get(to, "params.id"),
  });

  next();
};

export const customerStudentsForm = async (to, from, next) => {
  await store.dispatch("setStudents", { perPage: -1 });
  await store.dispatch("setCustomers", { perPage: -1 });
  await store.dispatch("setCustomerCourses");

  next();
};

export const customersData = async (to, from, next) => {
  if (_.get(to, "params.id")) {
    await store.dispatch("setCustomer", _.get(to, "params.id"));
  }

  await store.dispatch("setSignup", { formList: true });

  next();
};
