import { getLanguages } from "@/services/languageService";

const state = {
  languages: [],
  languagesLoading: false,
};

const getters = {
  getLanguages: (state) => state.languages,
  getLanguagesLoading: (state) => state.languagesLoading,
};

const mutations = {
  setLanguages(state, payload) {
    state.languages = payload;
  },
  setLanguagesLoading(state, payload) {
    state.languagesLoading = payload;
  },
};

const actions = {
  setLanguagesLoading({ commit }, payload) {
    commit("setLanguagesLoading", payload);
  },

  async setLanguages({ commit }) {
    try {
      commit("setLanguagesLoading", true);
      const { data } = await getLanguages();

      commit("setLanguages", data);
    } catch (error) {
      console.error(error);
    }

    commit("setLanguagesLoading", false);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
