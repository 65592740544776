import { objToUrlParam } from "@/shared/utils";
import axios from "axios";

export const getAll = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/customer?${objToUrlParam(payload)}`
  );
};

export const getCustomersFilter = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/customer/filter?${objToUrlParam(payload)}`
  );
};

export const create = (payload) => {
  return axios.post(`${process.env.VUE_APP_API_URL}/customer`, payload);
};

export const edit = (id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/customer/edit/${id}`,
    payload
  );
};

export const destroy = (id) => {
  return axios.delete(`${process.env.VUE_APP_API_URL}/customer/${id}`);
};

export const getById = (id) => {
  return axios.get(`${process.env.VUE_APP_API_URL}/customer/${id}`);
};

export const getCustomerStudents = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/customer/get-students?${objToUrlParam(
      payload
    )}`
  );
};

export const addCustomerStudents = (id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/customer/add-students/${id}`,
    payload
  );
};

export const removeCustomerStudent = (id) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/customer/remove-student/${id}`
  );
};

export const notifyCustomerStudents = (id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/customer/notify-students/${id}`,
    payload
  );
};

export const toggleCustomerDisabled = (id, payload) => {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/customer/toggle-disabled/${id}`,
    payload
  );
};

export const checkUsername = (payload) => {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/customer/check-username?${objToUrlParam(
      payload
    )}`
  );
};
