import { create, getAll, update, updateCustomers } from "@/services/franchiseService";
import { get } from "lodash";
import { destroy } from "../../services/franchiseService";

const state = {
  franchises: [],
  franchisesLoading: false,
};

const getters = {
  getFranchises: (state) => state.franchises,
  getFranchisesLoading: (state) => state.franchisesLoading,
};

const mutations = {
  setFranchises(state, payload) {
    state.franchises = payload;
  },
  setFranchisesLoading(state, payload) {
    state.franchisesLoading = payload;
  },
};

const actions = {
  setFranchisesLoading({ commit }, payload) {
    commit("setFranchisesLoading", payload);
  },
  async setFranchises({ commit }) {
    try {
      commit("setFranchisesLoading", true);
      const { data } = await getAll();
      commit("setFranchises", data);
    } catch (error) {
      console.error(error);
    }

    commit("setFranchisesLoading", false);
  },
  async createFranchise({ commit }, payload) {
    try {
      commit("setFranchisesLoading", true);
      const { data } = await create(payload);
      commit("setFranchisesLoading", false);

      return data;
    } catch (error) {
      console.error(error);
      commit("setFranchisesLoading", false);

      return { error: get(error, "data") };
    }
  },
  async updateFranchise({ commit }, { id, payload }) {
    try {
      commit("setFranchisesLoading", true);
      const { data } = await update(id, payload);
      commit("setFranchisesLoading", false);

      return data;
    } catch (error) {
      console.error(error);
      commit("setFranchisesLoading", false);

      return { error: get(error, "data") };
    }
  },
  async updateFranchiseCustomers({ commit }, { id, payload }) {
    try {
      commit("setFranchisesLoading", true);
      const { data } = await updateCustomers(id, payload);
      commit("setFranchisesLoading", false);

      return data;
    } catch (error) {
      console.error(error);
      commit("setFranchisesLoading", false);

      return { error: get(error, "data") };
    }
  },
  async deleteFranchise(store, id) {
    return destroy(id);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
